<template>
  <v-container fluid class="pa-1" v-if="user_info.role <= 2">
    <v-row>
      <v-col>
        <v-file-input
          show-size
          multiple
          clearable
          label="เลือกภาพ"
          prepend-icon="mdi-camera"
          v-model="file"
          accept="image/jpeg"
          @click:clear="clear"
          @change="change"
        ></v-file-input>
      </v-col>
      <v-col>
        <v-btn
          :disabled="!file.length > 0"
          @click="imgup"
          class="mt-2"
          :loading="loading"
        >
          Upload all
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-resize="onResize"
          :items="table"
          :headers="headers"
          class="elevation-1"
          :hide-default-footer="true"
          disable-pagination
          fixed-header
          :height="windowSize.y - 180"
          dense
          :mobile-breakpoint="0"
        ></v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
const token = localStorage.getItem("token");
axios.defaults.headers.common = {
  Authorization: `Bearer ${token}`,
  // Accept: "application/json",
};

export default {
  data: () => ({
    headers: [
      {
        text: "ชื่อไฟล์",
        align: "start",
        sortable: false,
        value: "filename",
      },
      {
        text: "ขนาดไฟล์",
        align: "start",
        sortable: false,
        value: "filesize",
      },
      {
        text: "ชนิดไฟล์",
        align: "start",
        sortable: false,
        value: "filetype",
      },
    ],
    file: [],
    table: [],
    windowSize: {
      x: 0,
      y: 0,
    },
    loading: false,
  }),
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    async change() {
      this.table = await [];
      for await (const file of this.file) {
        var filesizecal = (await file.size) / 1024;
        var dat = await {
          filename: file.name,
          filesize: filesizecal.toFixed(2) + " kB",
          filetype: file.type,
        };
        await this.table.push(dat);
      }
    },
    async imgup() {
      this.loading = await true;
      for await (const file of this.file) {
        let formData = await new FormData();
        await formData.append("image", file);
        await axios
          .post(`${this.$store.state.apiurl}/api/imgup`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            var index = this.table.findIndex((x) => x.filename == res.data);
            this.table.splice(index, 1);
          })
          .catch(() => {});
      }
      this.file = await [];
      this.loading = await false;
    },
    clear() {
      this.file = [];
      this.table = [];
      this.loading = false;
    },
  },
  computed: {
    ...mapState(["user_info"]),
    // image_url: function () {
    //   return `${this.$store.state.apiurl}/api/imgup`;
    // },
  },
};
</script>

<style></style>
